import { shortenString } from "../../utils/formatData";

export default function ParticipateHisItem({
  lstProduct,
}: {
  lstProduct: any;
}) {
  console.log(lstProduct);

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <img
            src={lstProduct?.receipt_image}
            className="w-20 max-h-[170px] object-cover rounded-tl-xl rounded-bl-xl"
          />
        </div>
        <div className="containerNotify__background-itemChild pl-1 pr-2 box-border py-2 col-span-9">
          <div className="grid grid-cols-9 text-[13px] font-semibold-mon">
            <div className="col-span-3">Tên siêu thị</div>
            <div className="font-regular-italic col-span-6 text-reponsive text-right">
              {lstProduct?.title}
            </div>
          </div>
          <div className="content-notify grid grid-cols-9 font-semibold-mon text-[13px]">
            <div className="col-span-4">Số hóa đơn</div>
            <div className="font-light-mon text-right col-span-5">
              {shortenString(lstProduct?.receipt_no)}
            </div>
          </div>
          <div className="content-notify grid grid-cols-9 font-semibold-mon text-[13px]">
            <div className="col-span-4">Trạng thái</div>
            <div
              className={`${
                lstProduct?.status === "sale"
                  ? "text-green"
                  : lstProduct?.status === "quotation"
                  ? "text-orange"
                  : "text-red"
              } font-italic-mon text-right col-span-5`}
            >
              {lstProduct?.status === "sale"
                ? "Hợp lệ"
                : lstProduct?.status === "quotation"
                ? "Đang chờ duyệt"
                : "Không hợp lệ"}
            </div>
          </div>
          <div className="text-[13px] grid grid-cols-2 font-semibold-mon">
            <div className="col-span-1">Ngày dự thưởng</div>
            <div className="font-light-mon col-span-1 text-right">
              {lstProduct?.date}
            </div>
          </div>
          <div className="flex justify-end">
            <button className=" px-5 py-2 bg-[#16A5A3] text-white font-bold-go text-[12px] mt-1 rounded-xl">
              XEM HÓA ĐƠN
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
