import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { LoginServices } from "../../services/apiService/LoginService";
import { setAuthorization } from "../../services/apiService/configURL";
import { userLocal } from "../../services/localService/localService";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const styleButton = {
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
};
function ConfirmOTP() {
  document.body.style.backgroundColor = "white";
  const navigation = useNavigate();
  const [otp, setOtp] = useState("");
  const phone = localStorage.getItem("PHONE");
  let redirect = localStorage.getItem("REDIRECT");
  console.log(redirect?.split("/undefined"));

  const nameLogin = localStorage.getItem("NAME");
  const handleChangeOtp = (e: any) => {
    setOtp(e.target.value);
  };
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  const resendOTP = () => {
    const data = {
      phone: phone as string,
    };
    LoginServices.ValidateAPI(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    setMinutes(1);
    setSeconds(0);
  };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleRecaptchaExecution = async (action: any) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha is not yet available");
      return null;
    }
    return await executeRecaptcha(action);
  };
  const onSubmit = async () => {
    const data = { otp: otp };
    const token = await handleRecaptchaExecution("form_submit");

    const datatest = Object.assign(data, {
      phone: phone,
      name: nameLogin,
      recaptcha_token_response: token,
    });
    let getValue = redirect ? redirect : "";
    setIsLoading(true);
    LoginServices.ValidateLoginApi(datatest)
      .then((res: any) => {
        console.log(res);
        setAuthorization(res.fe_token);
        userLocal.set(res);
        navigation(`/${res?.fe_token}${getValue ? getValue : ""}`);
        localStorage.setItem("CHECK_STATUS", "TRUE");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <header className="icon-back mt-[7%] py-[36px] h-8 flex items-center opacity-100 max-w-full w-full z-50">
          <i className="fa-solid fa-chevron-left fa-solid-back"></i>
        </header>
        <header className="uppercase w-52 h-9 leading-9 text-[24px] font-bold-go text-[#16A5A3]">
          nhập mã otp
        </header>
        <div className="container__login-item  p-[16px_0_68px_0] text-[#333] text-[16px] font-regular-mon">
          Một mã xác định gồm 6 chữ số đã gửi đến số điện thoại{" "}
          <span className="color-phone text-[#16A5A3] font-bold-mon">
            {" "}
            {phone}
          </span>{" "}
          qua Zalo/SMS. Vui lòng kiểm tra tin nhắn
        </div>
        <div className="container__input">
          <div className="flex justify-center border-[#BEC5D2] rounded-md border-[1px]">
            <input
              onChange={handleChangeOtp}
              className=" h-16 rounded-lg w-full text-[#333333] text-center input-hidden"
              type="tel"
            />
          </div>
          <div
            className="
            mt-5
            text-[#333333] flex justify-center font-regular-mon mb-16"
            style={{ marginTop: "10px" }}
          >
            Không nhận được mã
            <span className="font-regular-mon" style={{ color: "#16A5A3" }}>
              <span>
                <div className="countdown-text">
                  {seconds > 0 || minutes > 0 ? (
                    <>
                      <p>
                        <span> &nbsp;</span>
                        {minutes < 10 ? ` 0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    </>
                  ) : (
                    <button
                      disabled={seconds > 0 || minutes > 0}
                      className=" text-[#16A5A3] mx-1.5"
                      onClick={resendOTP}
                    >
                      Gửi lại
                    </button>
                  )}
                </div>
              </span>
            </span>
          </div>
          <div className="flex justify-center py-[56px] box-border text-[#363534] font-light-mon">
            {otp?.length === 6 ? (
              <Fragment>
                {!isLoading ? (
                  <input
                    style={styleButton}
                    id="button__D"
                    type="button"
                    className="color-button-enable button-login font-bold-go h-[56px] text-[#ffffff]"
                    value="Xác nhận mã"
                    onClick={() => onSubmit()}
                  />
                ) : (
                  <div className="color-button-enable w-[195px] button-login font-bebas-mon flex justify-center h-[56px]">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : (
              <input
                style={styleButton}
                id="button__D"
                type="button"
                className="color-button-disable button-login text-white font-regular-go h-[56px]"
                value="Xác nhận mã"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default ConfirmOTP;
