import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { DATA_USER } from "../localService/localService";

const headers: AxiosRequestConfig["headers"] = {
  "Content-Type": "application/json",
};
export const axiosInstance = axios.create({
  baseURL: "https://samplingbe.mvctech.vn",
  headers,
});
export const axiosOCRInstance = axios.create({
  baseURL: "https://lytspvb.mvctech.vn",
  headers: {
    "Content-Type": "application/json",
  },
});
export const setAuthorization = (token: string) => {
  console.log(token);
  axiosInstance.defaults.headers.Authorization = "Bearer " + token;
};
axiosInstance.interceptors.response.use(
  function (response) {
    console.log(response);
    if (response.data?.meta?.status) {
      return response?.data?.data;
    } else {
      if (response.data?.meta?.status_code === 403) {
        // window.location.assign("/login");
      }
      return Promise.reject(response.data?.meta?.message);
    }
  },
  function (error: AxiosError) {
    console.log(error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 400:
        message = "Access token invalid";
        break;
      default:
        message = error.message || error;
    }

    return Promise.reject(message);
  }
);
