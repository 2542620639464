import { useEffect, useState } from "react";
import "./App.css";
import {
  isLocalhost,
  isMobileDevice,
  isSupportedBrowser,
} from "./utils/deviceModel";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { userLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import { detectIncognito } from "detectincognitojs";
import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/Login/Login";
import ConfirmOTP from "./pages/Login/ValidateOTP";
import Waiting from "./components/CountDraw/Waiting";
import CountDown from "./components/CountDraw/CountDown";
import ListGift from "./pages/ListGift/ListGift";
import ListGame from "./pages/ListGame/ListGame";
import InforCustomer from "./pages/Information/InforCustomer";
import ListGiftForCustomer from "./pages/ListGiftCustomer/ListGiftCustomer";
import ListHistory from "./pages/ListHistory/ListHistory";
import PrizeRules from "./pages/PrizeRule/PrizeRule";
import PlayGame from "./pages/PlayGame/PlayGame";
import NoAccessBrowser from "./pages/NoAccessBrowser/NoAccessBrowser";
import GuideTakeAPhoto from "./pages/GuideTakeAPhoto/GuideTakeAPhoto";
import AboutUs from "./pages/AboutUs/AboutUs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import CheckGift from "./pages/ExchangeGifts/CheckGift";

function App() {
  let access = false;
  document.body.style.backgroundColor = "white";
  let mobileDevice = isMobileDevice();
  let supportedBrowser = isSupportedBrowser();
  if ((mobileDevice && supportedBrowser) || isLocalhost()) {
    if (window.innerWidth < 450) {
      access = true;
    }
  }
  const { id } = useParams();
  let { fe_token } = userLocal.get();
  console.log(id);
  setAuthorization(id as string);
  const queryClient = new QueryClient();
  const [isPrivate, setIsPrivate] = useState(false);
  useEffect(() => {
    detectIncognito().then((result: any) => {
      if (result.isPrivate === false) {
        setIsPrivate(true);
      }
    });
  }, []);
  return (
    <div className="font-regular-so ">
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfJ-OAqAAAAAF5ytkaQBZqKalv21TQkOBejwoDK"
        language="en"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {access && isPrivate ? (
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route path="/:id?" element={<HomePage />} />
                <Route path="/:id/games" element={<ListGame />} />
                <Route path="/:id/video-game" element={<PlayGame />} />
                <Route path="/:id/prize" element={<PrizeRules />} />
                <Route path="/:id/gifts" element={<ListGift />} />
                <Route path="/:id/aboutus" element={<AboutUs />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path="/" element={<Login />} /> */}
                <Route path="/:id/infor-customer" element={<InforCustomer />} />
                <Route path="/:id/list-history" element={<ListHistory />} />
                <Route
                  path="/list-infor-customer"
                  element={<ListGiftForCustomer />}
                />
                <Route path="/validate-otp" element={<ConfirmOTP />} />
                <Route path="/waiting" element={<Waiting />} />
                <Route path="/:id/countdown" element={<CountDown />} />
                <Route
                  path="/:id/guide-takeaphoto"
                  element={<GuideTakeAPhoto />}
                />
                <Route path="/doiqua" element={<CheckGift />} />
              </Routes>
            </QueryClientProvider>
          </BrowserRouter>
        ) : (
          <NoAccessBrowser />
        )}
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
