import GIFT_CHANGE from "../../assets/image/ExchangeGift/gift-change.png";
import INPUT from "../../assets/image/ExchangeGift/font-input.png";
import CAM from "../../assets/image/ExchangeGift/btn-cam.png";
import { useState } from "react";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import uuid from "react-uuid";
import Compressor from "compressorjs";
import { useEffect } from "react";
import QRCodeDoiqua from "./QRCodeDoiqua";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import { homeServices } from "../../services/apiService/homeService";
import ItemList from "./ItemList";
import { URL_API_UPLOAD_GCS, apiNodeJS } from "../../services/api/apiNode";
import PopupNotify from "../../components/Popup/PopupError";
import { useParams } from "react-router-dom";

const TITLE = "Đổi quà";
export default function CheckGift() {
  const [cam, setCam] = useState(true);
  const [selected, setSelected] = useState("environment");
  const [imageFile, setimageFile] = useState(undefined);
  const [dataImage, setDataImage] = useState();
  const [result, setResult] = useState("");
  const [mess, setMess] = useState("");
  const [dataCheck, setDataCheck] = useState(undefined);
  const [isUpload, setIsUpload] = useState(false);
  const [openCam, setOpenCam] = useState(false);
  const [image, setImage] = useState(undefined);
  const [notify, setNotify] = useState(false);
  const [code, setCode] = useState(null);
  const [showDialog, setDiaglog] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(undefined);
  const { id } = useParams();

  const hanldleOK = () => {
    setNotify(false);
    setimageFile(undefined);
    setIsUpload(false);
    setDataCheck(undefined);
    setData(undefined);
  };
  const handleOpenCam = () => {
    setCam(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const pushImageToGCS = () => {
    console.log("hello");
    let formDataGCS = new FormData();
    console.log("minh");
    formDataGCS.append("file", imageFile);
    const fileName =
      uuid() +
      "_" +
      format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
      "_" +
      imageFile.name;
    formDataGCS.append("fileName", fileName);
    setIsUpload(true);
    apiNodeJS
      .postUploadToNode(URL_API_UPLOAD_GCS, formDataGCS)
      .then((res) => {
        console.log(res);
        setDataImage(res?.public_url);
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setIsUpload(false);
      });
  };
  useEffect(() => {
    if (dataImage !== undefined) {
      const data = {
        so_id: dataCheck?.so_id,
        public_url: dataImage,
      };
      homeServices
        .postExchangeGift(data)
        .then((res) => {
          console.log(res);
          setMess(res);
          setNotify(true);
          setIsUpload(false);
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  }, [dataImage]);
  const onClickDeleteImg = (e) => {
    setimageFile(undefined);
    setImage(undefined);
    setOpenCam(true);
  };
  let refInputUpload = useRef(null);
  const onClickUpload = (event) => {
    refInputUpload.current?.click();
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const onSubmit1 = () => {
    console.log(data);

    homeServices
      .getCheckCode(data)
      .then((res) => {
        setError("");
        setDataCheck(res);
        const check = checkAvailability(res?.prize_list, "Chưa đổi");
        setDiaglog(check);
        console.log(res);
      })
      .catch((err) => {
        setError(err);
      });
  };
  console.log(dataCheck);

  const handleChangeImage = (event) => {
    console.log(event);
    let fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    const fileUploadedSize = fileUploaded.size / 1024 / 1024;
    if (fileUploadedSize > 20) {
      new Compressor(fileUploaded, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
      new Compressor(fileUploaded, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
      new Compressor(fileUploaded, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
      new Compressor(fileUploaded, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else {
      setimageFile(fileUploaded);
    }
    console.log(fileUploaded);
  };

  console.log(imageFile);
  const onChangeQR = (e) => {
    setData(e.target.value);
  };

  function checkAvailability(arr, val) {
    return arr.some((arrVal) => val === arrVal?.status_value);
  }
  return (
    <>
      {isUpload ? (
        <div className="z-[200] w-full  flex justify-center items-center absolute full-height backdrop-blur-sm flex-col">
          <div className="">
            <button className="w-[100px] h-[100px] load"></button>
          </div>
          <div className="flex justify-center text-center px-[30px] ">
            <p className="bg-slate-100 rounded-xl font-light p-[10px] ">
              Mã đổi quà của quý khách đang được hệ thống ghi nhận. Vui lòng
              không rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {notify ? (
        <PopupNotify
          handleOk={hanldleOK}
          labelButtonOK="Đồng ý"
          notify={"Thông báo"}
          content={
            "Mã " +
            mess?.exchange_code +
            " đổi quà thành công lúc " +
            mess?.exchange_time
          }
          isShow={notify}
        />
      ) : (
        <div className="contain">
          <HeaderBackground title={TITLE} buttonBack={``} disable={true} />
          <div className="containerNotify__checkcode bg-[#fff] max-h-full absolute rounded-[30px_30px_0_0] bottom-0 h-[88%] w-full z-10">
            <ul className="containerNotify__checkcode-list box-border z-20 grid-rows-2 border-2 rounded-lg">
              {imageFile !== undefined ? (
                <div>
                  <div className="takepic-img-wrap">
                    <img
                      src={URL.createObjectURL(imageFile)}
                      className="mt-4 px-[20px]"
                      alt="upload view"
                    />
                  </div>

                  <div className="flex justify-center gap-[12px] p-4">
                    <button
                      onClick={(e) => onClickDeleteImg(e)}
                      className="bg-[#96C61C] border-0 text-[#ffffff] px-[32px] py-[15px] text-center no-underline inline-block rounded-[13px] text-[16px] cursor-pointer not-italic font-[Montserrat-Light] font-black leading-5"
                    >
                      Chụp lại
                    </button>

                    <button
                      onClick={() => {
                        pushImageToGCS();
                      }}
                      className="bg-[#0A63C1] border-0 text-[#FFFFFF] px-[32px] py-[15px] text-center no-underline inline-block rounded-[13px] text-[16px] cursor-pointer not-italic font-[Montserrat-Light] font-black leading-5"
                    >
                      Xác nhận
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {" "}
                  {cam ? (
                    <div>
                      <div className="w-full top-10 flex justify-center">
                        <img src={GIFT_CHANGE} className="" />
                      </div>{" "}
                      <div className="flex flex-col items-center">
                        <div className="text-[#014FA2] text-[20px]">
                          Nhập mã quà tặng
                        </div>
                        <div className="flex mt-5">
                          <div className="relative">
                            <img src={INPUT} />
                            <div
                              className="relative w-8 left-5 z-50 -top-11"
                              onClick={handleOpenCam}
                            >
                              <img src={CAM} />
                            </div>
                            <input
                              className="absolute top-2 left-1/2 w-[170px] -translate-x-1/2 h-10 pl-2 text-center input-hidden"
                              placeholder="Nhập mã quà tặng"
                              value={data}
                              onChange={(e) => onChangeQR(e)}
                            />
                          </div>
                          <button
                            className="px-2 h-14 btn-check"
                            type="submit"
                            onClick={() => onSubmit1()}
                          >
                            Kiểm tra
                          </button>
                        </div>
                        <div className="font-semibold z-0 -mt-5 text-[red] text-[13px] text-center">
                          {error ? error : <></>}
                        </div>
                      </div>
                      {dataCheck !== undefined ? (
                        <>
                          <div className="grid grid-cols-5 gap-1 px-2">
                            <div className="col-span-2 font-regular">
                              Tên Chương trình:
                            </div>
                            <div className="col-span-3 text-right text-[13px] text-[#014FA2]">
                              {dataCheck?.campaign_name}
                            </div>
                            <div className="col-span-2 font-regular">
                              Siêu thị:
                            </div>
                            <div className="col-span-3 text-right font-semibold text-[13px]">
                              {dataCheck?.store_name}
                            </div>
                            <div className="col-span-2 font-regular">
                              Mã hóa đơn:
                            </div>
                            <div className="col-span-3 text-right text-[13px]">
                              {dataCheck?.receipt_no}
                            </div>
                            <div className="col-span-2 font-regular">
                              Ngày mua hàng:
                            </div>
                            <div className="col-span-3 text-right text-[13px]">
                              {dataCheck?.receipt_date}
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-1 px-2 mt-5">
                            {dataCheck?.prize_list?.map((res, index) => (
                              <>
                                <ItemList res={res} key={index} />
                              </>
                            ))}
                          </div>
                          {showDialog !== false ? (
                            <div
                              onClick={(e) => onClickUpload(e)}
                              className="relative top-3 btn-check w-40 text-center py-4 left-1/2 -translate-x-1/2"
                            >
                              Đổi quà
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <> </>
                      )}
                      <input
                        onChange={(e) => handleChangeImage(e)}
                        className="uploadFile"
                        style={{ display: "none" }}
                        id="upload"
                        type="file"
                        capture
                        accept="image/*"
                        ref={refInputUpload}
                      />
                    </div>
                  ) : (
                    <div>
                      <QRCodeDoiqua setData={setData} setCam={setCam} />
                    </div>
                  )}
                  <div className="h-40"></div>
                </>
              )}
            </ul>
          </div>
          {/* <FooterLogo /> */}
        </div>
      )}
    </>
  );
}
