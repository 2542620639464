import Logos from "../../assets/image/Icon/logo_mvc.png";

export default function Logo() {
  const handleMVC = () => {
    window.open("https://mvc.com.vn", "_blank", "nooperner");
  };
  return (
    <>
      <div
        onClick={handleMVC}
        className="fixed z-50 bottom-0 w-full px-2 flex justify-between items-center h-5 font-normal text-[13px] leading-5 bg-[#ffffff]"
      >
        <img src={Logos} className="w-24" />
        <div className="text-[14px] font-bold-mon"></div>
        <div className="text-[10px] text-center font-semibold-mon pl-2">
          © 2024 <span className="font-regular-mon">Powered by </span>
          <div className="-mt-[10px]">MVCTECH.</div>
        </div>
      </div>
    </>
  );
}
