import { useNavigate } from "react-router-dom";

export default function ItemCampaign({
  channel_image_url,
  channel_name,
  channel_id,
}: any) {
  return (
    <div className="relative z-0 rounded-2xl">
      <img
        src={channel_image_url}
        loading="lazy"
        alt="Image"
        className="relative z-0 rounded-2xl bg-[#F2F5F9]"
      />
    </div>
  );
}
